import { AWS_ASSET_HOST } from '../consts/env.consts';
import { IMAGE_SIZES } from '../consts/images.const';

export class ImageHelper {
    constructor(image, previewType) {
        this.originalUrl = typeof image === 'string' ? image : image.imageAws;
        this.id = image.id;
        this.previewType = previewType;
        this.imgUrl = this.getImageUrl();
    }

    static getUrlFromOptions(imageUrl, imageOptions) {
        if (
            imageUrl &&
            typeof imageUrl === 'string' &&
            (imageUrl.includes(AWS_ASSET_HOST) || imageUrl.includes('cloudfront'))
        ) {
            let url = imageUrl.split('/');
            let placeOptionsInUrl = 0;
            if (imageUrl.includes(AWS_ASSET_HOST)) {
                placeOptionsInUrl = 3;
            } else if (imageUrl.includes('cloudfront')) {
                placeOptionsInUrl = url.findIndex((e) => e.includes('cloudfront')) + 1;
            }
            url.splice(placeOptionsInUrl, 0, imageOptions.replace(/ /g, ''));
            return url.join('/');
        }
        return imageUrl;
    }

    getImageUrl() {
        const opt = this._sizeFromScreenType();
        return ImageHelper.getUrlFromOptions(this.originalUrl, opt.join(''));
    }

    _sizeFromScreenType() {
        const screenTypes = Object.keys(IMAGE_SIZES[this.previewType]);
        let opt = [];
        // const retina = window.devicePixelRatio > 1;
        screenTypes.forEach((type) => {
            const { SCREEN, IMAGE } = IMAGE_SIZES[this.previewType][type];
            if (this._isMatchSize(SCREEN.FROM, SCREEN.TO)) {
                opt = [`fit-in/0x${IMAGE.HEIGHT}`];
            }
        });
        return opt;
    }

    _getOptions(optionsHorizontal, optionsVertical, size) {
        return [
            'if_w_gt_h/',
            [...optionsHorizontal, size].join(),
            '/if_else,',
            [...optionsVertical, size].join(),
            '/if_end',
        ];
    }
    _getSizeForFull() {
        let w, h;
        if (window.innerWidth > window.innerHeight) {
            h = Math.round((window.innerHeight * 80) / 100);
            w = Math.round(h * 1.77777);
        } else {
            w = Math.round(window.innerWidth);
            h = Math.round(w * 1.77777);
        }

        return `w_${w}, h_${h}`;
    }

    _isMatchSize(sizeFrom, sizeTo) {
        if (sizeTo) {
            return window.matchMedia(`(min-width: ${sizeFrom}px) and (max-width: ${sizeTo}px)`).matches;
        }
        return window.matchMedia(`(min-width: ${sizeFrom}px)`).matches;
    }
}
