const HORIZONTAL = 'HORIZONTAL';
const VERTICAL = 'VERTICAL';
const VIEW = 'VIEW';
const PREVIEW = 'PREVIEW';
const FULL = 'FULL';
const MAP = 'MAP';

const IMAGE_BORDER_COLOR = 'd8d8d8';

export const IMG_TYPE_OPT = {
    HORIZONTAL: {
        MAP: ['c_fill'],
        PREVIEW: ['c_fill'],
        VIEW: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
        FULL: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
    },
    VERTICAL: {
        MAP: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
        PREVIEW: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
        VIEW: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
        FULL: ['c_pad', `b_rgb:${IMAGE_BORDER_COLOR}`],
    },
};

export const IMG_TYPE = {
    [HORIZONTAL]: HORIZONTAL,
    [VERTICAL]: VERTICAL,
};

export const PREVIEW_TYPES = {
    [VIEW]: VIEW,
    [PREVIEW]: PREVIEW,
    [FULL]: FULL,
    [MAP]: MAP,
};

export const IMG_EXT = ['image/*', 'image/png', 'image/jpeg', 'image/jpg'];

export const IMAGE_SIZES = {
    [VIEW]: {
        EXTRA_SMALL: {
            SCREEN: { FROM: 0, TO: 580 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        SMALL: {
            SCREEN: { FROM: 580, TO: 768 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        MEDIUM: {
            SCREEN: { FROM: 768, TO: 990 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        LARGE: {
            SCREEN: { FROM: 990, TO: 1200 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        EXTRA_LARGE: {
            SCREEN: { FROM: 1200, TO: 1500 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        HUGE: {
            SCREEN: { FROM: 1500 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
    },
    [PREVIEW]: {
        EXTRA_SMALL: {
            SCREEN: { FROM: 0, TO: 576 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        SMALL: {
            SCREEN: { FROM: 576, TO: 768 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        MEDIUM: {
            SCREEN: { FROM: 768, TO: 990 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        EXTRA_MEDIUM: {
            SCREEN: { FROM: 990, TO: 1140 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        LARGE: {
            SCREEN: { FROM: 1140, TO: 1300 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        EXTRA_LARGE: {
            SCREEN: { FROM: 1300, TO: 1400 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
        HUGE: {
            SCREEN: { FROM: 1400 },
            IMAGE: { WIDTH: 1500, HEIGHT: 750 },
        },
    },
    [FULL]: {
        ALL: {
            SCREEN: { FROM: 0, TO: 3000 },
            IMAGE: { WIDTH: 0, HEIGHT: 3000 },
        },
    },
    [MAP]: {
        ALL: {
            SCREEN: { FROM: 0, TO: 3000 },
            IMAGE: { WIDTH: 700, HEIGHT: 350 },
        },
    },
};
