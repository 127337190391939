export const googlePlayLink = 'intent://sniffspot?listing#Intent;scheme=sniffspot;package=com.sniffspot;end';
export const googlePlayLinkUrl = 'https://play.google.com/store/apps/details?id=com.sniffspot';
export const appleStoreLink = 'https://apps.apple.com/us/app/sniffspot/id1437699295';
export const instagramLink = 'https://www.instagram.com/sniffspots/';
export const facebookLink = 'https://www.facebook.com/sniffspots/';
export const tiktokLink = 'https://www.tiktok.com/@sniffspot';
export const youtubeLink = 'https://www.youtube.com/channel/UCewyUhZqykqQMmQgI1Bdmfw';
export const fmdLink = 'https://www.familydogmediation.com/';
export const ppgLink = 'https://www.petprofessionalguild.com/';
export const fbHostGroupLink = 'https://www.facebook.com/groups/sniffspothost';
export const EMAIL_SUPPORT = 'support@sniffspot.com';
