import React, { useState } from 'react';
import defAvatar from '../../assets/svg/SvgProfile.svg';
import emptyPhoto from '../../assets/img/empty-spot.png';
import { ImageHelper } from '../../classes/image-helper.class';
import { AWS_STATIC_ASSET_HOST } from '../../consts/env.consts';
import './style.scss';

const PictureSet = ({
    imgs: img = '',
    isAvatar,
    className = '',
    noLoad,
    method = 'lazy',
    SSR,
    useState: useStateSSR,
    pictStyle = {},
    creatorName,
    white,
    ...restImageProps
}) => {
    const [loaded, setLoaded] = SSR ? useStateSSR(false) : useState(false);
    const imgs = img || (isAvatar ? defAvatar : emptyPhoto);
    const isString = typeof imgs === 'string';
    const defaultPath = isString ? imgs : imgs?.images?.length ? imgs.images[imgs.images.length - 1].path : '';
    const extension = defaultPath.split('.').pop();
    const webpUrl = ImageHelper.getUrlFromOptions(defaultPath, 'filters:format(webp)');
    const isStaticAsset = isString && defaultPath.includes(AWS_STATIC_ASSET_HOST);
    const itemType = creatorName ? 'https://schema.org/Person' : 'https://schema.org/Organization';

    const onLoad = () => setLoaded(true);

    if (!defaultPath) {
        return '';
    }

    return (
        <picture style={pictStyle} itemScope itemType="https://schema.org/ImageObject">
            {isString ? (
                !isStaticAsset ? (
                    <>
                        <source type="image/webp" srcSet={webpUrl} />
                        <source type={`image/${extension == 'jpg' ? 'jpeg' : extension}`} srcSet={defaultPath} />
                    </>
                ) : (
                    ''
                )
            ) : (
                imgs.images.map((img, j) => {
                    let media = undefined;

                    if (imgs.images.length > 1) {
                        media =
                            j > 0 && j == imgs.images.length - 1
                                ? `(min-width: ${imgs.images[j == 0 ? j : j - 1].width}px)`
                                : `(max-width: ${img.width}px)`;
                    }

                    return (
                        <React.Fragment key={j}>
                            <source type="image/webp" srcSet={img.path + '.webp'} media={media} />
                            <source type={`image/${extension == 'jpg' ? 'jpeg' : extension}`} srcSet={img.path} media={media} />
                        </React.Fragment>
                    );
                })
            )}
            <img
                itemProp="contentUrl"
                {...restImageProps}
                loading={method}
                className={`${className}${SSR || noLoad || loaded ? '' : ' pic-tag-img'}${white ? ' white' : ''}`}
                onLoad={onLoad}
                src={defaultPath}
            />
            <span itemProp="creator" itemType={itemType} itemScope>
                <meta itemProp="name" content={creatorName || 'Sniffspot'} />
            </span>
        </picture>
    );
};

export default PictureSet;
